import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-831905f4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-layout"
};
const _hoisted_2 = {
  class: "page-router-main-content"
};
import Navbar from "./navbar.vue";
import Footer from "./footer.vue";
export default {
  __name: 'layout',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(Navbar), _createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component,
          route
        }) => [(_openBlock(), _createBlock(_KeepAlive, {
          include: _ctx.getCaches
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: route.fullPath
        }))], 1032, ["include"]))]),
        _: 1
      })]), _createVNode(Footer)]);
    };
  }
};