import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Layout from '@/layout/layout.vue'

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory('/suanxz-web/'),
  base: '/suanxz-web/',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }

  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/home',
      component: Layout,
      children: [
        {
          path: 'home',
          component: () => import('@/pages/home/index.vue'),
          name: '首页',
        },
        {
          path: 'product',
          name: '产品服务',
          children: [
            {
              path: 'erp',
              component: () => import('@/pages/product/erp.vue'),
              name: '蒜小账ERP',
            },
            {
              path: 'ai',
              component: () => import('@/pages/product/ai.vue'),
              name: '蒜小智AI',
            },
            {
              path: 'wms',
              component: () => import('@/pages/product/wms.vue'),
              name: '蒜小仓',
            },
            {
              path: 'robot',
              component: () => import('@/pages/product/robot.vue'),
              name: '小蒜机器人',
            },
          ]
        },
        {
          path: 'case',
          component: () => import('@/pages/case/index.vue'),
          name: '客户案例',
        },
        {
          path: 'service',
          component: () => import('@/pages/service/index.vue'),
          name: '服务保障',
        },
        {
          path: 'about',
          component: () => import('@/pages/about/index.vue'),
          name: '关于我们',
        },
      ]
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/pages/errorPage/404.vue'),
    },
  ]
})

export default router
