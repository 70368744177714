import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app-router"
};
import useWindowResize from "@/hooks/useWindowResize";
import { onMounted } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    const windowResizeData = useWindowResize();
    onMounted(() => {
      showCustomerSercvice();
    });
    function showCustomerSercvice() {
      if (windowResizeData.isMobile) {
        //移动端 h5
        console.log("移动端 h5: ");
        (function (w, d, e, x) {
          w[e] = function () {
            w.cbk = w.cbk || [];
            w.cbk.push(arguments);
          };
          x = d.createElement("script");
          x.async = true;
          x.id = "dueroCustomerWxScript";
          x.src = "https://kf-im-tx.dustess.com/customer-service/sdk/customer-service-im-js-sdk.js?sysNum=VzAwMDAwMDI0Nzg3LDY1NmZkYjFlZTRiMGViZDk5OWYxOWU5Mw==&openKfId=wkJawBCQAAbdDL6tHoQVqmlsrT8xaZRQ&webType=1&sceneType=1";
          d.body.appendChild(x);
        })(window, document, "cf");
      } else {
        console.log("web pac ");
        // 尘锋客服
        (function (w, d, e, x) {
          w[e] = function () {
            w.cbk = w.cbk || [];
            w.cbk.push(arguments);
          };
          x = d.createElement("script");
          x.async = true;
          x.id = "dueroCustomerWxScript";
          x.src = "https://kf-im-tx.dustess.com/customer-service/sdk/customer-service-im-js-sdk.js?sysNum=VzAwMDAwMDI0Nzg3LDY1NmZkYjFlZTRiMGViZDk5OWYxOWU5Mw==&openKfId=wkJawBCQAAbdDL6tHoQVqmlsrT8xaZRQ&webType=0&sceneType=1";
          d.body.appendChild(x);
        })(window, document, "cf");
      }
    }
    return (_ctx, _cache) => {
      const _component_RouterView = _resolveComponent("RouterView");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_RouterView)]);
    };
  }
};