import { onMounted, onUnmounted, ref, reactive } from "vue";

function useWindowResize() {
  const width = ref(0);
  const height = ref(0);

  const isMobile = ref(false)

  function onResize() {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
    if (width.value <= 768) {
      isMobile.value = true
    } else {
      isMobile.value = false
    }
  }

  onMounted(() => {
    window.addEventListener("resize", onResize);
    onResize();
  });

  onUnmounted(() => {
    window.removeEventListener("resize", onResize);
  });



  return reactive({ width, height, isMobile });
}

export default useWindowResize;